import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "../../utils/axios";
import {useSnackbar} from "../../components/snackbar";

// eslint-disable-next-line react/prop-types
function ConfirmDepartmentDelete({ depId, depName,deleteDepConfirmModalIsOpen,setDeleteDepConfirmModalIsOpen,handleCloseModal }) {
  const [isDeleting, setIsDeleting] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = () => {
    if(!isDeleting) {
      setIsDeleting(true)
      const jwt = localStorage.getItem('axjwt');
      if (jwt) {
        const postValues = new FormData();
        postValues.append('action', 'delete_department');
        postValues.append('depid', depId);
        axios.post('https://api.administrix.pro/v1/post/',
          postValues, {
            headers: {'Authorization': `Bearer ${jwt}`}
          })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;
              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

              if (data.success === 1) {
                enqueueSnackbar("Department sme úspešne odstránili.", {variant: `success`, autoHideDuration: 6000});
                handleCloseModal()
              } else {
                enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`, autoHideDuration: 6000});
                console.log(response.data)
              }
            } else if (response.data.error) {
              const {error} = response.data;
              enqueueSnackbar(`Chyba pri odstraňovaní departmentu: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
            }
            setIsDeleting(false)

          }))
          .catch(error => {
            console.log("any deleteting dep fetch api error");
            setIsDeleting(false)
          })
      }
      setDeleteDepConfirmModalIsOpen(false)
    }
  };

  return (
    <Dialog open={deleteDepConfirmModalIsOpen}>
      <DialogTitle>Vymazanie departmentu</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Z hlavnej databázy AdministriXu odstránime záznamy pre tento department: Informácie o departmente, DB, FTP, zoznam domén, todo, incidencia užívateľov a pravidelné platby.<br /><br />
          Užívatelia a firmy napojené na tento department ostanú neporušené. Tiež sa nezmažú dáta, ktoré sú súčasťou webového sídla, ktoré je napojené na tento department.
          <br /><br />
          Skutočne chcete vymazať tento department ({depName})?<br />

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} color="primary">
          Áno
        </Button>
        <Button onClick={()=>setDeleteDepConfirmModalIsOpen(false)} color="primary">
          Nie
        </Button>

      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDepartmentDelete;
