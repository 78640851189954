import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

// eslint-disable-next-line import/no-extraneous-dependencies
import CloseIcon from '@mui/icons-material/Close';
// eslint-disable-next-line import/no-extraneous-dependencies
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
// eslint-disable-next-line import/no-extraneous-dependencies
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// eslint-disable-next-line import/no-extraneous-dependencies
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import {QueuePlayNext} from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {useAxStore} from "../store/axStore";

const ConfirmExistingUserModal = ({open, email, status, depname,setOpen,canEdit, setConfExistUserModalResponse,setUserIdToEdit,userid}) => {
/*

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  */

  const axStore=useAxStore();

  return (
    <Dialog open={open}  maxWidth="sm" fullWidth>
        <DialogTitle>
          Užívateľ {email} už v systéme existuje.
          <IconButton
            aria-label="close"
             onClick={()=>setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {status===1&&
              <>
                Prajete si ho priradiť do departmentu {depname} ?
              </>
            }
            {status===2&&
              <>
                Tento uživateľ je už priradený do {depname}.
              </>
            }
          </DialogContentText>
        </DialogContent>
      <DialogActions>


        {(status===1) && <LoadingButton
          /* type="submit" */
          // onClick={submitUserSave}
          variant="contained"
          size="large"
          // loading={isSubmitting}
          startIcon={<PersonAddIcon/>}
          sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
        >
          Priraď užívateľa
        </LoadingButton>
        }

        {(status===2&&canEdit===1) && <LoadingButton
          /* type="submit" */
          onClick={()=>setUserIdToEdit(userid)}
          variant="contained"
          size="large"
          // loading={isSubmitting}
          startIcon={<ManageAccountsIcon/>}
          sx={{ml: 2, backgroundColor: '#063056', color: '#fff'}}
        >
          Uprav nastavenia užívateľa
        </LoadingButton>
        }

        <Button variant="outlined" onClick={()=>setOpen(false)}>
          Zatvor
        </Button>
      </DialogActions>
      </Dialog>
  );
};
ConfirmExistingUserModal.propTypes = {
  open: PropTypes.bool,
  email:PropTypes.string,
  status:PropTypes.number,
  canEdit:PropTypes.number,
  userid:PropTypes.number,
  depname:PropTypes.string,
  setConfExistUserModalResponse:PropTypes.func,
  setUserIdToEdit:PropTypes.func,
  setOpen:PropTypes.func,

};
export default ConfirmExistingUserModal;
