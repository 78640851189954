// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import {SettingsProvider, SettingsDrawer, useSettingsContext} from 'src/components/settings';
// sections
import { CheckoutProvider } from 'src/sections/checkout/context';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
// import {useMemo, useState} from "react";
// import {createPortal} from "react-dom";
// import { esSK } from '@mui/x-date-pickers-localized/locales';
import skLocale from 'date-fns/locale/sk';
import { skSK } from '@mui/x-date-pickers/locales';
import {useEffect, useRef} from "react";

import {allLangs, useLocales} from 'src/locales';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import {useAxStore} from "./store/axStore";
// import EditArticleModal from "./modal/edit-article-modal";
// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang');
  // const defaultDomain = useRef("administrix.pro")
  // const defaultDomain = useRef("pixme.sk")
  const defaultDomain= "pixme.sk"
  let currentDomain = defaultDomain
  let currentSubDomain = null
  const { i18n, t } = useTranslation();

  const settings = useSettingsContext();
//  const currentDomain = useRef(defaultDomain.current)
//  const currentSubDomain = useRef(null)
   // const currentDomain = useRef("administrix.pro")

  // currentDomain.current=window.location.hostname
  let currentHostname = window.location.hostname;
  if(currentHostname==="administrix5.pro")currentHostname="administrix.pro"
  const parts = currentHostname.split('.');
  currentDomain = currentHostname.replace(/(?:.*\.)?([^.]+\.[^.]+)$/, '$1');
// If there is a subdomain, it will be the first part of the hostname
  if(currentHostname.includes('localhost')) {
    currentSubDomain = parts.length > 1 ? parts[0] : null;
    currentDomain=defaultDomain;
  }
  else {
    currentSubDomain = parts.length > 2 ? parts[0] : null;
//    currentDomain.current = currentHostname.current
  }
  if(currentSubDomain&&currentSubDomain==='new'&&currentDomain === "pixme.sk")currentSubDomain='';

  // console.info('running on ',currentHostname,'. Domain:',currentDomain,' and subdomain:',currentSubDomain)
  const axStore=useAxStore()



  // if(currentDomain.current.includes('administrix')) {
    let logoPathValue = "https://new.administrix.pro/assets/images/logo.png"
    let logoPathValue2 = "https://new.administrix.pro/assets/images/ax-logo-2.png"
    let colorCodeValue = "blue"
    let systemNameValue = "AdministriX"
    let systemNameGenitivValue = "AdministriXu"
    let systemNameLokalValue = "AdministriXe"
    let systemEmailvalue = "admin@administrix.pro"
    let companyNameValue = "r65 studio s.r.o."
    let systemVersionValue='v5.00 beta'
    let systemDeployDateValue='11.6.2024'


  if(currentDomain.includes('pixme.sk')||currentDomain.includes('pixme.pro'))
  {
    // console.info('setting pixme values')
    logoPathValue = "https://new.administrix.pro/assets/images/logo-pixme.png"
    logoPathValue2  = "https://new.administrix.pro/assets/images/logo-pixme-2.png"
    colorCodeValue = "red"
    systemNameValue = "piXme"
    systemNameGenitivValue = "piXme"
    systemNameLokalValue = "piXme"
    systemEmailvalue = "admin@pixme.sk"
    companyNameValue = "piXme s.r.o."
    systemVersionValue='v2.016 beta'
    systemDeployDateValue='17.09.2024'

  }



  useEffect(() => {

     axStore.setSystemCurrentConfig({
       logo: logoPathValue,
       logo2: logoPathValue2,
       logoDep: logoPathValue2,
       logoDepW: logoPathValue2,
       themeColorPresets:colorCodeValue,
       systemName:systemNameValue,
       systemNameGenitiv:systemNameGenitivValue,
       systemNameLokal:systemNameLokalValue,
       systemEmail:systemEmailvalue,
       subdomain:currentSubDomain,
       companyName:companyNameValue,
       systemVersion:systemVersionValue,
       systemDeployDate:systemDeployDateValue,
     });
    // console.log(allLangs)
    if(lang) {
      const isLangSupported = allLangs.some(language => language.value === lang);
      if (isLangSupported) {

        i18n.changeLanguage(lang);
        // settings.onChangeDirectionByLang(lang);
        console.info('app useeffect changing lang to ', lang)
      }
    }

// console.info('app useeffect domain ',currentDomain)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
/*
  useEffect(() => {
    console.info('app useeffect lang ', lang)
    if(lang) {
      i18n.changeLanguage(lang);
      // settings.onChangeDirectionByLang(lang);

      console.info('app useeffect changing lang to ', lang)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
*/
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}  localeText={skSK.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <SettingsProvider
          defaultSettings={{
            // themeMode: axStore.axUserDepConfig.themeMode, // 'light' | 'dark'
             themeMode: 'dark', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            // themeContrast: axStore.axUserDepConfig.themeContrast, // 'default' | 'bold'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            // themeColorPresets: axStore.systemCurrentConfig.themeColorPresets, // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
             themeColorPresets: colorCodeValue, // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            // themeColorPresets: colorCode.current, // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            // themeColorPresets: 'orange', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >

          <ThemeProvider>
            {/* axStore.openModalEditArticle && editArticleModalContent */}

            <MotionLazy>
              <SnackbarProvider>
                <CheckoutProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <Router systemNameValue={systemNameValue} subDomainValue={currentSubDomain}/>
                  </AuthConsumer>
                </CheckoutProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>

  );
}

