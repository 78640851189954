import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';


import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import {TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {useAxStore} from "../store/axStore";
import {RHFTextField} from "../components/hook-form";
import Iconify from "../components/iconify";
import {useSnackbar} from "../components/snackbar";
import {useBoolean} from "../hooks/use-boolean";
import axios from "../utils/axios";

const Ax4UserDepIncQuickEditForm = ({open, setOpen, userId,depId}) => {

  const axStore=useAxStore();
  const { enqueueSnackbar } = useSnackbar();
  const ax4DepUserIdEdit = useRef(0)

  useEffect(()=> {
    if(open) {
      // console.log('axuserdep modal opened, user',userId)

      let headers={}
      const jwt = localStorage.getItem('axjwt');
      if(jwt) {
        headers = {
          'Authorization': `Bearer ${jwt}`
        }
        const params = {
          'action': 'get_ax4_user_dep_settings',
          'uid': userId,
          'depid': depId,
        }
        axios.get('https://api.administrix.pro/v1/get/', {
          headers,
          params
        })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;

              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
              if (data.settings) {
                setFormData(prevFormData => ({
                  ...prevFormData,  // keep previous formData values
                  ...data.settings       // update with new settings values
                }));

              }
            }else
            if (response.data.error) {
              const {error} = response.data;
              // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
            }



          }))
          .catch(error => {

            // console.log("any force_select_department fetch api error");
            // console.log(error.title);

            // setErr(error);
            // setIsUploadedFilesQueueProcessing(false)
            // return (final_user)
          })
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  const [formData, setFormData] = useState({
    default_page: '',
    menu: '',
    role: -1,
    dashboard_menu: '',
    action:'save_ax4_user_dep_setting'
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async () => {


    const jwt = localStorage.getItem('axjwt');
    if (jwt) {

      const postData = new FormData();
      Object.keys(formData).forEach(key => postData.append(key, formData[key]));
      postData.append('uid',userId)
      postData.append('depid',depId)


      axios.post('https://api.administrix.pro/v1/post/',
        postData, {
          headers: {'Authorization': `Bearer ${jwt}`}
        })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if (data.success === true) {

                enqueueSnackbar("Výborne, nastavenia sme uložili!", {variant: `success`, autoHideDuration: 6000});

              } else {
                enqueueSnackbar("Bohužiaľ, nastavenia sa nám nepodarilo uložiť :/", {variant: `error`, autoHideDuration: 6000});

              }


          } else if (response.data.error) {
//            const {error} = response.data;
            enqueueSnackbar(`Chyba : ${response.data.error.title}`, {variant: `error`, autoHideDuration: 6000});

          }

        }))
        .catch(error => {
          console.log("any saving user ax4 setting  fetch api error");

        })

    }


//    setOpen(false)
  };






  return (

    <Dialog open={open}  maxWidth="sm" fullWidth >
        <DialogTitle>
          Nastavenia usera-department pre AX4
          <IconButton
            aria-label="close"
             onClick={()=>setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
  <Grid sm={12}>




      <TextField
        fullWidth
        margin="normal"
        id="menu"
        name="menu"
        label="Menu"
        value={formData.menu}
        onChange={handleChange}
        helperText="názov menu, napr.obec-admin, web-clanky..."
      />


      <TextField
        fullWidth
        margin="normal"
        id="dashboard_menu"
        name="dashboard_menu"
        label="Dashboard menu"
        value={formData.dashboard_menu}
        onChange={handleChange}
        helperText="názov dashboard menu, napr.ds-clanky"
      />


      <TextField
        fullWidth
        margin="normal"
        id="default_page"
        name="default_page"
        label="Default page"
        value={formData.default_page}
        onChange={handleChange}
        helperText="názov dashboard menu, napr.articles alebo articles/22  atd"
      />

      <TextField
        fullWidth
        margin="normal"
        id="role"
        name="role"
        label="Role"
        value={formData.role}
        onChange={handleChange}
        helperText="role pre deparment"
      />


      <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth sx={{marginBottom:"20px",marginTop:"20px"}}>
        Ulož zmeny
      </Button>



  </Grid>
          </DialogContentText>
        </DialogContent>

      </Dialog>
  );
};
Ax4UserDepIncQuickEditForm.propTypes = {
  open: PropTypes.bool,
  setOpen:PropTypes.func,
  userId:PropTypes.number,
  depId:PropTypes.number

};
export default Ax4UserDepIncQuickEditForm;
